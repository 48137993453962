import { Component, Input, OnInit } from '@angular/core';
import { WidgetHttpService } from 'src/app/core/http/widget-http.service';
@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss']
})
export class NewsWidgetComponent implements OnInit {
  @Input() params;
  loadingSpinner = false;

  items: any = [];
  constructor(private widgetService: WidgetHttpService) {
  }

  ngOnInit(): void {
    this.loadingSpinner = true;

    this.widgetService.initilizeWidget({
      widgetId: this.params.id,
      initArgs: this.params.json.params.initialize_args,
      code: this.params.json.code
    }).subscribe((res) => {
      this.items = res.data;

    }, () => {
      this.loadingSpinner = false;
    }, () => {
      this.loadingSpinner = false;
    });
  }
  openNews(news: any) {

    window.open('/news/show/' + news.id + '/true','_blank');
  }

}
