<div class="card border-0 mt-2" [ngStyle]="{'min-height':'380px'}">
  <div
    class="card-header border-0 rounded-0 bgHeaderColor text-white
    font-weight-bold">
    {{ params.title }}
  </div>
  <div class="card-body p-2">
    <ng-container *ngIf="!loadingSpinner">
      <div  class="d-flex flex-row mt-2 border-bottom" *ngFor="let item of items" (click)="openNews(item)" style="cursor: pointer;">
        <div class="p-2 d-flex flex-column justify-content-center">
          <i class="icon-calendar1" [ngStyle]="{ 'font-size': '34px' }"></i>
        </div>
        <div class="d-flex flex-column w-100">
          <div class="d-flex justify-content-start">
            <span class="calendar-text">{{item.title}}</span>
          </div>
          <div class="d-flex flex-row justify-content-between
            calendar-text-details">
            <span>::{{item.type}}</span>
            <span>{{item.date_new }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="d-flex justify-content-center" *ngIf="loadingSpinner">
      <div class="spinner-border text-center text-primary" style="width: 2rem;
        height: 2rem;margin-top: 102px" role="status">
        <span class="sr-only" translate>chrg</span>
      </div>
    </div>
  </div>
</div>
